import Header from "../../layout/header";
import Footer from "../../layout/footer";
// import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Seo from "../../seo";
import { Link } from "gatsby";
import Fb from "../../../images/facebook.svg";
import CopyLink from "../../../images/copylink.svg";
import TwitterIcon from "../../../images/twitter.svg";
import { Facebook, Twitter } from "react-social-sharing";
import Avatar from "../../../images/avatar-shashank.png";

const Index = (props) => {
  // const location = useLocation();
  const data = props.pageContext.data;
  const content = data?.content?.data?.content;
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setUrl(window.location.href);
    }
  }, []);
  // const url = location.href;

  return (
    <React.Fragment>
      <Header />
      <Seo
        description={data.meta_description}
        title={data.meta_title}
        keywords={data.meta_keywords}
        slug={data.slug}
        img={data.cover_pic.url}
      />
      <div className="w-11/12 sm:w-10/12 md:w-11/12 md:px-3 md:max-w-2xl lg:max-w-none xl:max-w-6xl mx-auto lg:w-full mb-[80px]">
        <div className=" mx-5">
          <div className="mt-11 text-sm space-x-4">
            <div className="px-1 flex items-center gap-4">
              {data.tags?.map(({ name }, index) => {
                return (
                  <p className="px-4 py-1 bg-[#D9D9D9] rounded-full max-w-fit text-[14px] cursor-pointer">
                    {name}
                  </p>
                );
              })}

              <p
                className={`${
                  data?.tags?.length >= 1 ? "hidden md:block" : "block"
                }`}
              >
                {moment(data.publishedAt).format("DD MMMM YYYY")}
              </p>
            </div>

            <p className="md:hidden mt-4">
              {moment(data.publishedAt).format("DD MMMM YYYY")}
            </p>
          </div>
          <h1 className="font-roboto text-[40px] text-[#1F1F39] mt-10">
            {data.title}
          </h1>

          <div className="md:flex md:items-center md:justify-between gap-4 my-8">
            <div className="flex items-center space-x-4">
              <img src={Avatar} className="w-[53px] h-[53px] rounded-2xl" />
              {/* <div className="w-[53px] h-[53px] bg-[#FFE5E6] rounded-2xl"></div> */}
              <div>
                <span className="font-bold">By</span>
                {data.author !== "" ? (
                  <span className="px-1 font-bold">
                    {data.author || "Shashank Jain"}
                  </span>
                ) : (
                  <a
                    href="https://www.linkedin.com/in/shashankjain92/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="px-1 font-bold">Shashank Jain</span>
                  </a>
                )}
              </div>
            </div>
            <div>
              <div className="mt-4 md:mt-none flex items-center space-x-4">
                <Twitter
                  solid
                  small
                  link={url}
                  style={{
                    background: "#EAFAFA",
                    borderRadius: "50%",
                    width: "36px",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#3D948E",
                  }}
                />
                <Facebook
                  solid
                  small
                  link={url}
                  style={{
                    background: "#EAFAFA",
                    borderRadius: "50%",
                    width: "36px",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#3D948E",
                  }}
                />
                <img
                  src={CopyLink}
                  className="cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      props.location.href ? props.location.href : ""
                    );
                    setShow(true);
                    setTimeout(() => {
                      setShow(false);
                    }, 500);
                  }}
                />
                <p
                  className={`text-[#28ada7] text-sm md:hidden ${
                    show ? "block" : "invisible"
                  }`}
                >
                  Copied to clipboard
                </p>
              </div>
              <p
                className={`text-[#28ada7] text-sm hidden md:block ${
                  show ? "text-right" : "invisible"
                }`}
              >
                Copied to clipboard
              </p>
            </div>
          </div>
          <OptimizedImage
            src={data.cover_pic.url}
            alt={data.title}
            className="w-full xl:w-full object-cover h-auto lg:h-[586px] max-h-80 my-6 md:mb-24 rounded-xl blog_details"
          />
          <div
            className="mb-4 text-xl font-roboto blog_details_content break-words"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="space-y-4 bg-[#EAFAFA] my-12 py-12 px-8 rounded-2xl">
            <h1 className="text-4xl">
              Make Your Links More <br /> Memorable
            </h1>
            <p className="text-semibold">
              Shorten your urls into branded, readable format
              <br /> with{" "}
              <Link to="/" className="text-[#3D948E]">
                Teenyfy
              </Link>{" "}
            </p>
            <a
              href="https://app.teenyfy.com/user/register"
              className="bg-[#3D948E] px-12 py-3 rounded-3xl text-white inline-block"
            >
              Shorten Link
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default Index;
